.main {
    height: 100vh;
    position: fixed;
    background-color: #fff;
    width: 20%;
    overflow-Y: scroll;
    transition-delay: .2s;

    .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15%;

    }

    .menu {
        width: 86%;
        margin: 0px 6%;
        border-radius: 10px;

    }
}