@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;300&family=Cabin&family=Shantell+Sans:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #e6f3fb72;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    // font-family: 'Cabin', sans-serif;
    font-family: 'Be Vietnam Pro', sans-serif;
}