@media only screen and (min-width: 300px) and (max-width: 900px) {
    .infoImage {
        width: 90%
    }
}

@media only screen and (min-width: 900px) and (max-width: 1800px) {
    .infoImage {
        width: 35%
    }
}