.main {
    width: 78% !important;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 10px !important;
    position: fixed;
    left: 20%;
    z-index: 1;
    background-color: hsl(205, 78%, 51%);
    height: 10%;
    top: 2%;
    display: flex;


    .menu {
        background-color: #fff;
        padding: 0px 10px 0px 10px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}